.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.title-amount {
  font-size: 1.2rem;
}

.price-comparison .right {
  float: right;
}

.price-comparison .left {
  float: left;
}

.fifty-two-week-price-comparison {
  width: 200px;
  height: 10px;
  border: 2px solid #333;
  overflow: hidden;
}

.fifty-two-week-price-comparison .right-border {
  height: 10px;
  border-right: 2px solid #333;
}

.mm-table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-row-group {
  display: table-row-group;
}

.table-cell {
  display: table-cell;
}

.net-worth {
  font-size: 1.4rem;
}

.small-link {
  font-size: 1.0rem;
}

.balance-change {
  font-size: 0.8rem;
}

.net-worth > .table-cell:first-child,
.net-worth > .table-cell:nth-child(2) {
  border-top: 2px solid #333;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.goal-container {
  position: absolute;
  right: 0;
  margin-top: -12px;
}

.buttons-container {
  position: absolute;
  left: 0;
  margin-top: -12px;
}

.border-div-container {
  background-color: #333;
  height: 8px;
}

.border-div-container .left {
  float: left;
}

.border-div-container .border {
  height: 8px;
}

.border-div-container .border.green {
  background-color: lightgreen;
}

.border-top {
  border-top: 2px solid #333;
}

.border-bottom {
  border-bottom: 2px solid #333;
}

.blue-bg {
  background-color: #F0F8FF;
}

.market-headline {
  font-weight: bold;
}

.market-headlines-list {
  display: inline-block;
  width: auto;
  text-align: left;
}

.market-headlines-list:empty {
  display: none;
}

.market-headlines-list li {
  width: auto;
}

.transaction-summary {
  font-weight: bold;
} 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.align-center {
  align-items: center;
}

.investments-table {
  border-spacing: 0;
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  margin-bottom: 100px;
  margin-top: 20px;
}

@media (min-width: 800px) {
  .investments-table {
    border-left: 2px solid #333;
    border-right: 2px solid #333;
  }
}

.investments-table thead > tr > th {
  background-color: #f8f8f8;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
}

.white-bg {
  background-color: #ffffff;
}

.investments-table th {
  border-bottom: 2px solid #333;
}

.investments-table .left-border {
  border-left: 2px solid #333;
}

.investments-table td,
.investments-table th  {
  padding: 4px;
}

.investments-table thead tr {
  background-color: #f8f8f8;
}

.investments-table tbody td {
  border-bottom: 1px solid #333;
}

.investments-table tr {
  min-height: 20px;
}

.investments-table tr:nth-child(even) {
  background-color: #F0F8FF;
}

.actionsCol {
  text-align: right;
}

.light-green {
  background-color: lightgreen;
}

.dark-green {
  background-color: darkgreen;
  color: #ffffff;
}

.light-red {
  background-color: lightpink;
}

.dark-red {
  background-color: darkred;
  color: #ffffff;
}

.heading-amount .block {
  font-size: 1.2rem;
}

.block {
  display: block;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.bold {
  font-weight: bold;
}

.pr-5px {
  padding-right: 5px;
}

.pl-0 {
  padding-left: 0;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.game-date { 
  display: block;
}

.side-panel {
  width: 100%;
}

.mb-20 {
  margin-bottom: 20px;
}

.holdings-col {
  position: fixed;
  right: 0;
  transition: 1s ease-in-out;
  background-color: #fff;
  opacity: 1;
  width: 100%;
  top: 0;
  height: 100vh;
  overflow-y: auto;
}

.portfolio-movement {
  font-size: 0.8rem;
}

.portfolio-movement .table-row {
  font-weight: normal;
}

.w100 {
  width: calc(100% - 30px);
}

.holdings-col .panel-buttons {
  width: 100%;
  align-self: flex-start;
  padding: 15px 15px 0 15px;
  text-align: left;
}

.holdings-col .closePanelBtn {
  float: right;
}

.holdings-col .holdingsBtn,
.holdings-col .closePanelBtn {
  align-self: flex-start;
}

.holdings-col.closed {
  transform: translateX(100%);
}

.holdings-col.open {
  transform: translateX(0);
}

.flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.asset-table {
  border-spacing: 0;
}

.asset-table th {
  padding-right: 10px;
}

.asset-table td:last-child {
  padding-left: 5px;
  text-align: left;
}

.portfolio-title .label {
  font-size: 24px;
}

.stock-market-pie-chart > div {
  max-width: 800px;
  width: 100%;
  margin: auto;
}

.flex-col {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.holdings-data {
  align-items: flex-start;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.justify-center {
  justify-content: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.selected-security-heading .security-price-col {
  align-items: flex-start;
}

.header-diversification-chart {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 0;
  padding-bottom: 5px;
}

@media (min-width: 800px) {
  .header-container {
    flex-direction: row;
  }

  
  .header-diversification-chart {
    width: 160px;
    align-items: center;
    padding-top: 5px;
  }
  
  .chart-container {
    width: 160px;
  }

}

.togglePortfolioLink {
  font-weight: bold;
}

.hide-mob {
  display: none;
}

@media (min-width: 800px) {
  .hide-mob {
    display: table-cell;
  }
}

.heading-amount {
  font-size: 1.2rem;
}

.balance-changes {
  margin-bottom: 4px;
}

.balance-changes .balance-change:first-child {
  text-align: right;
}

.balance-changes .balance-change:nth-child(2) {
  text-align: left;
}

.show-mob {
  display: inline;
}

@media (min-width: 850px) {

  .show-mob {
    display: none;
  }
}

.game-balances {
  padding-bottom: 5px;
}

.game-section {
  flex-direction: column;
} 

@media (min-width: 1000px) {
  .game-section {
    flex-direction: row;
  } 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.overflow-container {
  width: 100%;
  overflow-x: auto;
}

.table.order-summary-table {
  width: auto;
}

.order-summary-heading {
  flex-direction: column;
  text-align: center;
}

.order-summary-shares {
  text-align: center;
}

@media (min-width: 800px) {
  .order-summary-heading {
    flex-direction: row;
  }

  .order-summary-shares {
    text-align: right;
  }
}

.order-summary-table .btn-lnk {
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: baseline;
}

.order-summary-table .btn-group {
  margin: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
